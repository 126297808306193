
$main-font-stack: Arial, sans-serif;

* { margin: 0; padding: 0; }
html, body { height: 100%; }

body {
  background-color: #f8f8f8;
  color: #222;
  font-family: $main-font-stack;
  font-size: 18px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a { color: #007499; text-decoration: none; }
a:hover { text-decoration: underline; }

.align-right { float: right; }
.text-lg { font-size: 1.1em; }
.text-sm { font-size: 0.9em; }

.clearfix {
  &:before, &:after { content: ' '; display: table; }
  &:after { clear: both; }
  *zoom: 1;
}

.container {
  margin: 0 auto;
  width: 800px;
}

.container > .header {
  padding: 20px 0;
}

.container > .header h1.title {
  display: inline-block;
  font-size: 1em;
}

.container > .header h1.title a { color: #666; text-decoration: none; }
.container > .header h1.title a:hover { color: #222; }
.container > .header .links { padding: 0 0 0 20px; }

.container > .header .links a {
  color: #666;
  font-size: 0.9em;
  padding: 0 10px;
}

.site {
  padding: 20px 0;
}

.site-title > a { color: #666; font-weight: bold; }
.site-title > a:hover { color: #222; text-decoration: none; }

.site .posts { padding: 50px 0 0 0; }
.site .posts .month { color: #aaa; padding: 30px 0; }

.site .posts .post-listing {
  font-family: $main-font-stack;
  font-weight: bold;
  line-height: 1.8em;
  padding: 25px 0;
}
.site .posts .post-listing .title { font-size: 1.4em; }
.site .posts .published-on {
  color: #666;
  font-weight: normal;
  font-size: 0.7em;
  font-style: italic;
  padding: 0 20px 0 0;
}

.site .posts a { color: #666; }
.site .posts a:hover { color: #000; text-decoration: none; }

.site .post .category,
.site .posts .category {
  color: #666;
  font-weight: normal;
  padding: 0 5px;
  text-transform: uppercase;
}
.site .posts .category { font-size: 0.6em; }
.site .post .category { font-size: 0.8em; }

.site .post h1.title {
  font-size: 1.8em;
  line-height: 1.4em;
  padding: 30px 0 20px 0;
}
.site .post .meta {
  color: #666;
  font-size: 0.9em;
}

/* About Me Section Styling */
$color_1: #333;
$color_2: #666;
$color_3: #fff;
$font-family_1: 'Arial', sans-serif;
$background-color_1: #f9f9f9;
$background-color_2: #333;
$background-color_3: #555;

/* About Me Section Styling */
#about-me {
  background-color: #f9f9f9;
  padding: 80px 20px;
  text-align: left;
}

#about-me .container {
  max-width: 800px;
  margin: 0 auto;
}

#about-me h2 {
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
}

#about-me p {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
}

#about-me .about-button {
  display: inline-block;
  padding: 12px 30px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
}

#about-me .about-button:hover {
  background-color: #555;
  transition: background-color 0.3s ease;
}
/* About Me Section Styling End */

.site .post .content    {
  font-family: $main-font-stack;
  line-height: 1.8em;
  margin: 60px 0;
}
.site .post .content p  { margin: 20px 0; }
.site .post .content h1 { margin: 20px 0 0 0; }
.site .post .content h2 { font-size: 0.9em; }
.site .post .content ul,
.site .post .content ol { margin: 20px 30px; }

.site .post .content ul ul { margin: 5px 0 10px 20px; }

.site .post .content h1 { font-size: 1.6em; margin: 1.6em 0 0.6em 0; }
.site .post .content h2 { font-size: 1.5em; margin: 1.5em 0 0.5em 0; }
.site .post .content h3 { font-size: 1.4em; margin: 1.4em 0 0.4em 0; }
.site .post .content h4 { font-size: 1.3em; margin: 1.3em 0 0.3em 0; }
.site .post .content h5 { font-size: 1.2em; margin: 1.2em 0 0.2em 0; }
.site .post .content h6 { font-size: 1.1em; margin: 1.1em 0 0.1em 0; }

.site .post .content code {
  background: #f7f7f7;
  border-radius: 3px;
  padding: 3px;
}

.site .post .content {
  pre { padding: 20px; }

  pre > code {
    background: none;
    border: none;
    padding: 0;
  }

  div.highlight {
    font-size: 0.9em;
    line-height: 1.5em;
    margin: 40px 0;
    overflow: auto;
  }
}

.site .post .content blockquote {
  background: #fff;
  border: 1px solid #ddd;
  font-family: $main-font-stack;
  font-size: 1.05em;
  font-style: italic;
  line-height: 1.8em;
  margin: 40px 0;
  padding: 0 40px;
}


#post-footer { margin-bottom: 50px; }

#author-bio {
  border-top: 3px solid #eee;
  font-size: 0.9em;
  line-height: 1.6em;
  padding: 60px 0px;
  position: relative;

  .gravatar {
    margin: -42px 0 0 -42px;
    position: absolute; top: 0; left: 50%;
  }

  .gravatar img {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    height: 80px;
    padding: 4px;
    width: 80px;
  }
  .bio a { color: #007499; }
  .links { text-align: center; }
  .links a { padding: 0 10px; }
}

.subscribe {
  background: #f9f9fa;
  color: #888;
  padding: 30px 20px;
}

.subscribe-form {
  clear: both;
  padding: 20px 0;

  input { font-size: 13px; }
}

.subscribe-email {
  padding: 8px;
  width: 50%;

  &:focus { outline: 0; }
}

.subscribe-submit {
  background: #eee;
  border: 1px solid #bbb;
  color: #777;
  height: 36px;
  width: 25%;

  &:hover { background: #ddd; }
}

#prev-next-nav {
  font-size: 0.9em;
  margin: 30px 0 0 0;
  overflow: hidden;
  padding: 15px 0;
  width: 100%;
}

#prev-next-nav div {
  display: inline-block;
  float: left;
  margin: 25px 0 0 0;
}
#prev-next-nav .next {
  float: right;
  padding: 0 0 0 5px;
  text-align: right;
}

.container > .footer {
  color: #aaa;
  font-size: 0.6em;
  padding: 10px 0 40px 0;
  text-align: center;
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.social-share { margin: 40px 0 100px 0; }

.tweet-button {
  background-color: #1b95e0;
  border-radius: 5px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 0.7em;
  height: 20px;
  margin-right: 20px;
  padding: 5px 8px;
  position: relative;
}
.tweet-button:hover {
  background-color: #0c7abf;
  text-decoration: none;
}
.tweet-button > i {
  background: transparent 0 0 no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 72 72'%3E%3Cpath fill='none' d='M0 0h72v72H0z'/%3E%3Cpath class='icon' fill='%23fff' d='M68.812 15.14c-2.348 1.04-4.87 1.744-7.52 2.06 2.704-1.62 4.78-4.186 5.757-7.243-2.53 1.5-5.33 2.592-8.314 3.176C56.35 10.59 52.948 9 49.182 9c-7.23 0-13.092 5.86-13.092 13.093 0 1.026.118 2.02.338 2.98C25.543 24.527 15.9 19.318 9.44 11.396c-1.125 1.936-1.77 4.184-1.77 6.58 0 4.543 2.312 8.552 5.824 10.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163 0 6.345 4.513 11.638 10.504 12.84-1.1.298-2.256.457-3.45.457-.845 0-1.666-.078-2.464-.23 1.667 5.2 6.5 8.985 12.23 9.09-4.482 3.51-10.13 5.605-16.26 5.605-1.055 0-2.096-.06-3.122-.184 5.794 3.717 12.676 5.882 20.067 5.882 24.083 0 37.25-19.95 37.25-37.25 0-.565-.013-1.133-.038-1.693 2.558-1.847 4.778-4.15 6.532-6.774z'/%3E%3C/svg%3E");
  display: inline-block;
  height: 14px;
  position: relative;
  top: 2px;
  width: 14px;
}

@media screen and (max-width: 900px) {
  .container { width: 99%; }
}

@media screen and (max-width: 500px) {
  body { font-size: 16px; }
  .container > .header, .site { padding: 20px; }
  .site .post h2.title { font-size: 1.5em; }
  .top-nav-link { padding: 0 5px; }
}
